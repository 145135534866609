<template>
    <div>
        <div
            @click.stop="onFolderClick()"
            class="px-2 cursor-pointer fm-tree-folder"
            :class="{ active: fm.isActiveFolder(path) }"
        >
            <i v-if="isLoading" class="mdi mdi-loading mdi-spin"></i>
            <i v-else class="mdi" :class="[isOpen ? 'mdi-folder-open' : 'mdi-folder']"></i> {{ item.name }}

            <span class="actions">
                <i class="mdi mdi-folder-plus" @click.stop="showCreateDirectoryModal(path)"></i>
                <i class="mdi mdi-file-plus" @click.stop="showCreateFileDialog(path)"></i>
                <i class="mdi mdi-trash-can" @click.stop="deleteFileDialog(item.path, item.name)"></i>
            </span>
        </div>

        <div class="ml-2" v-if="fm.isOpen(path)">
            <folder-tree-item :key="item.name" v-for="item in children" :item="item" />
        </div>
    </div>
</template>

<script>
import FileManagerDialogsMixin from "./FileManagerDialogsMixin"
import pathJoin from "./pathJoin"

export default {
    name: "FolderTreeItem",

    mixins: [FileManagerDialogsMixin],

    props: {
        item: {
            required: true,
            type: Object
        }
    },

    inject: ["fm"],

    methods: {
        onFolderClick() {
            const path = this.path

            if (this.fm.state[path].isLoaded) {
                if (this.fm.isActiveFolder(path)) {
                    this.fm.toggleFolder(path)
                }
            } else {
                this.fm.loadFolderData(path)
            }

            this.fm.setActiveFolder(path)
        }
    },

    computed: {
        path() {
            return pathJoin(this.item.path, this.item.name)
        },
        state() {
            return this.fm.state[this.path]
        },

        isLoading() {
            return this.state && this.state.isLoading
        },

        isOpen() {
            return this.state && this.state.isOpen
        },

        children() {
            return (this.fm.folders[this.path] || []).filter((f) => f.type === "DIRECTORY")
        }
    }
}
</script>

<style lang="scss">
.fm-tree-folder {
    .actions {
        opacity: 0;
    }

    &:hover .actions {
        opacity: 1;
    }

    &.active {
        background-color: #09f;
        color: white;
    }
}
</style>
